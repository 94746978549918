import { graphql } from 'gatsby'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { mixins, colours } from 'styles'
import { getComponentFromRT, RichTextType } from 'helpers'

const ContentWrapper = styled.div`
  background-color: ${colours.white};
  .not-found-content {
    ${mixins.innerWrapperMixin}
    ${mixins.textSection}
    h2 {
      font-size: 2.4rem;
      font-weight: bold;
      color: ${colours.greyOne};
      margin-bottom: 2rem;
    }
    p {
      line-height: 3.2rem;
      color: ${colours.greyOne};
    }
    padding-top: 1.8rem;
    padding-bottom: 1rem;
  }
`

export interface NotFoundPageProps {
  data: {
    page: {
      sectionOne: RichTextType
    }
  }
}

export const NotFoundPage: FunctionComponent<NotFoundPageProps> = ({ data }) => (
  <ContentWrapper>
    <div className="not-found-content">{getComponentFromRT(data.page.sectionOne)}</div>
  </ContentWrapper>
)

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    page: contentfulPage(name: { eq: "404" }) {
      sectionOne {
        raw
      }
    }
  }
`
